import React from 'react'
import Image from '../Assets/header5.webp'
import Image2 from '../Assets/header2.webp'

const Staff = () => {
	return (
		<section id='Staff' className='bg2'>
			<div className="container">
				<div className="grid1">
					<div className="container image">
						<img src={ Image } alt="Staff" />
					</div>
					<div className="text-align-center align-items imageText">
						<h2>Staff teamet</h2>
						<p>Vores staff team består af 7 vidunderlige mennesker, men vi kan altid bruge flere! Så har du lyst til at hjælpe andre, og har flair for det, så send os en ansøgning!</p>
						<p>Vi søger alt fra Hjælpere til Moderators. Admin kan man senere søge, når man er blevet et velkendt ansigt på serveren, som staff.</p>
					</div>
				</div>
				<div className="grid2">
					<div className="text-align-center align-items imageText">
						<h2>Hjælp og spørgsmål</h2>
						<p>Skal du bruge staff-hjælp ingame, så kan du bruge /helpop for at komme i kontakt med en staff som er online.</p>
						<p>Er det et længere/kompliceret spørgsmål eller er der ingen staff online, så kan du hoppe ovre på vores Discord. Der kan du uden tvivl få fat på en af os! /discord</p>
					</div>
					<div className="container image">
						<img src={ Image2 } alt="Staff" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Staff