import React from 'react'

const Footer = () => {
	return (
		<footer>
			<ul>
				<li><a href="https://twitch.tv/jackboi" target='_blank' rel='noreferrer'>Twitch</a></li>
				<li><a href="https://www.youtube.com/@barenmc/featured" target='_blank' rel='noreferrer'>Youtube</a></li>
				<li><a href="https://www.tiktok.com/@barenmc" target='_blank' rel='noreferrer'>TikTok</a></li>
			</ul>
			<ul>
				<li><a href="https://barenmc.tebex.io" target='_blank' rel='noreferrer'>Shop</a></li>
				<li><a href="https://discord.gg/Nu5frcsah4" target='_blank' rel='noreferrer'>Discord</a></li>
			</ul>
		</footer>
	)
}

export default Footer