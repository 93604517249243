import './Baren.scss';
import Header from './Sections/Header';
import Info from './Sections/Info';
import Events from './Sections/Events';
import About from './Sections/About';
import Staff from './Sections/Staff';
import Elitemobs from './Sections/Elitemobs';
import Footer from './Sections/Footer';

function App () {
	return (
		<>
			<Header />
			<Info />
			<About />
			<Events />
			<Staff />
			<Elitemobs />
			<Footer />
		</>
	);
}

export default App;
