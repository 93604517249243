import React, {useState} from 'react'

const Home = () => {

	const [modal, setModal] = useState(false)

	const handleClick = () => {
		setModal(!modal)
	}

	return (
		<header>
			<div className="blur"></div>
			<nav>
				<ul>
					<li><a href="https://barenmc.tebex.io" target='_blank' rel='noreferrer'>Shop</a></li>
					<li><a href="https://discord.gg/Nu5frcsah4" target='_blank' rel='noreferrer'>Discord</a></li>
					<li><a href="https://www.youtube.com/@barenmc/featured" target='_blank' rel='noreferrer'>Youtube</a></li>
					<li><a href="https://www.tiktok.com/@barenmc" target='_blank' rel='noreferrer'>TikTok</a></li>
					<li><a href="https://twitch.tv/jackboi" target='_blank' rel='noreferrer'>Twitch</a></li>
				</ul>
			</nav>
			<div className="container align-items">
				<div className='header-text'>
					<h1>Baren</h1>
					<h2><span className='italic' onClick={handleClick}>Det faldne rige</span></h2>
					{/* <img src={arrow} alt="Arrow" className='arrow' /> */}
					<button onClick={handleClick}>Klik her for IP</button>
				</div>
				{
					modal &&
					<div className="modal">
						<h5 onClick={handleClick}>x</h5>
						<h6>play.barenmc.dk</h6>
					</div>
				}
			</div>
			<a href="#info" className='readmore'>Læs mere herunder!</a>
		</header>
	)
}

export default Home