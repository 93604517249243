import React from 'react'
import Image from '../Assets/about1.webp'
import Image2 from '../Assets/about2.webp'

const About = () => {
	return (
		<section id='About' className='bg2'>
			<div className="container">
				<div className="grid1">
					<div className="container image">
						<img src={ Image } alt="About" />
					</div>
					<div className="text-align-center align-items imageText">
						<h2>Det Faldne Rige</h2>
						<p>Det faldne Rige er historie delen af Baren. Så når der kommer en forlængelse af historien, i form af quests, så vil det blive en slags expansion. Det vil sige at alle de nye quests vil komme samtidig, og med en historie som fortsættelse til Det Faldne Rige.</p>
					</div>
				</div>
				<div className="grid2">
					<div className="text-align-center align-items imageText">
						<p>Men quests kommer ikke til at være den eneste del i det. Alle på serveren har sammen et mål. Det mål er en liste af resourcer, som skal til for at genopbygge Riget!</p>
						<p>Når det mål er nået, så vil der komme et event. Et event hvor de bedste spillere på serveren, skal dyste om at blive den næste konge og få titlen Konge.</p>
					</div>
					<div className="container image">
						<img src={ Image2 } alt="About" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default About