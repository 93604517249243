import React from 'react'
import Image from '../Assets/elitemobs1.webp'
import Image2 from '../Assets/elitemobs2.webp'

const Elitemobs = () => {
	return (
		<section id='Elitemobs' className='bg1'>
			<div className="container">
				<div className="grid1">
					<div className="container image">
						<img src={ Image } alt="Elitemobs" style={ { transform: 'scaleX(-1)' } } />
					</div>
					<div className="text-align-center align-items imageText">
						<h2>Elitemobs</h2>
						<p>Når du er ude i vildmarken, og har fået dit første sæt iron gear, så vil du kunne støde på random bosser.</p>
						<h2>Bosser og Gear</h2>
						<p>Hvor stærk/svær bossen er, afhænger af det gear du har på. Bosserne kan droppe gear, som har itemlevel. Jo højere itemlevel, jo stærkere gear/bosser.</p>
					</div>
				</div>
				<div className="grid2">
					<div className="text-align-center align-items imageText">
						<h2>Player vs Player</h2>
						<p>PvE er ikke det eneste, som vi har. Du vil kunne PvP andre players på forskellige måder. En af måderne er ved at /duel (player) Efter modstanderen har accepteret, så vil i kunne vælge hvilken arena, samt hvilket kit.</p>
						<p>Der vil også være GuildWars, hvor en guild kan udfordre end anden guild, om at dyste i en af vores battlegrounds.</p>
						<p>Nether og End er de eneste verdener, som har PvP på.</p>
					</div>
					<div className="container image">
						<img src={ Image2 } alt="Elitemobs" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Elitemobs