import React from 'react'
import Image from '../Assets/eventyr.webp'

const Info = () => {
	return (
		<main id='info' className='bg1'>
			<div className="container align-items">
				<div className='text-align-center'>
					<img src={Image} alt="Eventyr" />
					<h2>Start dit eventyr med os!</h2>
					<p>Baren - Det faldne Rige, er en survival server fyldt med</p>
					<p>sjov og hygge! Baren var oprindeligt lavet for 2 år siden,</p>
					<p>men har nu fået et totalt nyt look og playstyle!</p>
					<p>Denne gang har vi tilføjet plugins, så som: Elitemobs,</p>
					<p>som er Bosser og RPG-items!</p>
					<br />
					<br />
					<p>Herunder vil du kunne læse mere om Baren og om</p>
					<p>hvilken rolle Det Faldne Rige <span className='italic'>egentlig</span> har.</p>
				</div>
			</div>
		</main>
	)
}

export default Info