import React from 'react'
import Image from '../Assets/header3.webp'
import Image2 from '../Assets/header6.webp'

const Events = () => {
	return (
		<section id='Events' className='bg1'>
			<div className="container">
				<div className="grid1">
					<div className="container image">
						<img src={ Image } alt="Events" />
					</div>
					<div className="text-align-center align-items imageText">
						<h2>Begivenheder</h2>
						<p>For at maximere hygge niveauet helt til top, så har vi en række minigames, til når survival ikke er nok i sig selv!</p>
						<p>Elsker du parkour, og følelsen af at være nummer 1 på førertavlen?</p>
					</div>
				</div>
				<div className="grid2">
					<div className="text-align-center align-items imageText">
						<p>Måske er du en ægte fisker, og ville eeelske at deltage i en fisketurnering?</p>
						<p>Dette er blot nogle af de begivenheder, som vi kommer til at have. (Udover de sæson-begivenheder, som vi selvfølgelig også har hvert år!)</p>
					</div>
					<div className="container image">
						<img src={ Image2 } alt="Events" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Events